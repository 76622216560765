<template>
  <div>
    <!-- Activity table -->
    <div class="card"
         v-if="isGranted([permissions.INCIDENT_CATEGORY_SYSTEM_VIEW, permissions.INCIDENT_CATEGORY_SYSTEM_VIEW_OWN], object)"
         qid="activities-section"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <h5 class="float-left" qid="incident-activities-table-title">
              {{ $t('maintenance.incidents.incident_categories.systems.title') }}
            </h5>
            <div v-if="getGuide('update_systems')">
              <GuideButton :guide="getGuide('update_systems')"></GuideButton>
            </div>
          </div>
          <div class="col-sm-6 text-right">
            <button class="btn btn-success pl-3 pr-3"
                    @click="showCreateSModal()"
                    qid="incident-activities-create-button"
                    v-if="isGranted(permissions.INCIDENT_CATEGORY_SYSTEM_CREATE, object)"
            >
              <font-awesome-icon icon="plus"/> {{ $t('maintenance.incidents.incident_categories.systems.create_button') }}
            </button>
          </div>
        </div>

        <table class="table table-responsive-md table-list-secondary">
          <thead>
          <tr>
            <th scope="col">
              {{ $t('activities.table.name') }}
            </th>
            <th scope="col">
              {{ $t('system.last_updated') }}
            </th>
            <th width="100"></th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="object in tableItems">
              <td>
                <router-link
                    :to="{ name: 'systems.view', params: { slug: object.slug }}"
                    qid="s-view-page-link"
                >
                  {{ object.system.name }}
                </router-link>
              </td>
              <td>
                <span class="text-normal">
                  {{ object.updated_at | formatDate }}
                </span>
              </td>
              <td align="right">
                <span class="btn btn-delete"
                      qid="delete-s-modal"
                      v-if="isGranted(permissions.INCIDENT_CATEGORY_SYSTEM_DELETE, incident)"
                      @click="showConfirmDeleteModal(object)"
                >
                  <font-awesome-icon icon="trash-alt"/>
                </span>
              </td>
            </tr>
            <tr v-if="!sLoading && !tableItems.length" qid="no-results-row" class="text-center">
              <td colspan="10">
                <h5 class="mt-3">
                  {{ $t('system.no_results') }}
                </h5>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- <div class="card-body">
        <div class="pagination row">
          <div class="col-md-6">
            <div class="row">
              <div class="float-left">
                <span class="text-muted pagination-counter">
                  {{ $t('pagination.entries_per_page') }}:
                </span>
              </div>
              <div class="float-left">
                <multiselect
                  v-model="perPage"
                  :options="perPageOptions"
                  track-by="value"
                  label="value"
                  :select-label="$t('system.dropdown_select_label')"
                  :selected-label="$t('system.dropdown_selected_label')"
                  :deselect-label="$t('system.dropdown_deselect_label')"
                  qid="create-org-form-organization-level"
                  @select="perPageChanged"
                  :placeholder="selectedPerPage"
                  @remove="perPageRemoved"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <b-pagination
                class="float-right"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="selectedPerPage"
                aria-controls="my-table"
                @change="pageChanged"
                limit="1"
            >
              <span class="text-muted" slot="first-text"><font-awesome-icon icon="angle-double-left"/></span>
              <span class="text-muted" slot="prev-text"><font-awesome-icon icon="angle-left"/></span>
              <span class="text-muted" slot="next-text"><font-awesome-icon icon="angle-right"/></span>
              <span class="text-muted" slot="last-text"><font-awesome-icon icon="angle-double-right"/></span>
              <div slot="ellipsis-text">
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
              </div>
              <span class="text-muted" slot="page" slot-scope="{ page, active }">
                <span v-if="active">{{getPerPage}}</span>
              </span>
            </b-pagination>
          </div>
        </div>
      </div> -->

    </div>


    <!-- Delete incident modal template -->
    <b-modal ref="delete-system" hide-footer>
      <div class="d-block text-center">
        <h4 qid="delete-system-title">
          {{ $t('maintenance.incidents.incident_categories.systems.delete_modal_title') }}
        </h4>
      </div>
      <h5 class="text-center">
        {{ $t('processes.activity.delete.description') }}
        <span v-if="itemToDelete">{{ itemToDelete.name }}</span>
      </h5>
      <h5 class="text-center">
        {{ $t('processes.activity.delete.are_you_sure') }}
      </h5>
      <div class="text-center">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="hideConfirmDeleteModal"
                  qid="delete-system-cancel-button"
        >
          {{ $t('processes.activity.delete.cancel') }}
        </b-button>
        <b-button @click="confirmDeleteSystem(itemToDelete)"
                  class="mt-3 ml-3"
                  variant="success"
                  qid="delete-system-submit-button"
        >
          {{ $t('processes.activity.delete.submit') }}
        </b-button>
      </div>
    </b-modal>


    <!-- Add new system modal -->
    <b-modal ref="add-system" hide-footer @hide="hideCreateSModal">
      <div class="d-block text-center">
        <h4 qid="add-ac-title">
          {{ $t('maintenance.incidents.incident_categories.systems.add_modal_title') }}
        </h4>
      </div>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-ac-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="sFormSubmit" qid="create-ac-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-ac-pg-label">
              {{ $t('maintenance.incidents.incident_categories.systems.system_label') }}
            </label>
            <multiselect
                v-model="selectedSystem"
                :options="systemItems"
                :placeholder="$t('maintenance.incidents.incident_categories.systems.system_placeholder')"
                label="name"
                @search-change="loadP"
                track-by="slug"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                qid="create-ac-form-ac"
            ></multiselect>
          </b-form-group>
          <div v-if="selectedSystem && selectedSystem.description"
               class="text-center mb-3 mt-4"
          >
            <span class="cursor-pointer">
              <span class="mr-2"
                    @click="showSDescription = !showSDescription"
                    qid="expand-activity-description"
              >
                {{$t('maintenance.incidents.incident_categories.systems.description_expand')}}
              </span>
              <font-awesome-icon icon="chevron-down" v-if="!showSDescription"/>
              <font-awesome-icon icon="chevron-up" v-if="showSDescription"/>
            </span>
          </div>
          <div v-if="selectedSystem && selectedSystem.description && showSDescription" class="mb-4 mt-2">
            <span class="text-formatted">
              {{ selectedSystem.description }}
            </span>
          </div>

          <div v-if="selectedSystem && selectedSystem.tags_translated && selectedSystem.tags_translated.length">
            <label class="mt-3">
                {{ $t('processes.activity.create.tags_label') }}
            </label>
            <br>
            <span class="mr-1" v-for="tag in selectedSystem.tags_translated" qid="p-tags-list">
              <span class="badge badge-info mb-1" :title="tag.name_translated">
                {{tag.name_translated | truncate(35)}}
              </span>
            </span>
          </div>

        </div>
        <div class="text-right">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideCreateSModal"
                    qid="create-ac-cancel-button"
          >
            {{ $t('processes.activity.create.cancel') }}
          </b-button>
          <b-button type="submit"
                    class="mt-3 ml-3"
                    :disabled="buttonDisabled"
                    variant="success"
                    qid="create-ac-submit-button"
          >
            <span v-if="buttonDisabled" class="mr-1">
              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
            </span>
            {{ $t('processes.activity.create.submit') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>

  </div>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';
  import ShowMoreModal from '@/components/ShowMoreModal.vue';
  import SystemViewModal from '@/views/Systems/SystemViewModal.vue';
  import ActivityCreateForm from '@/views/Activities/ActivityCreateForm.vue';
  import GuideButton from '@/components/guide/GuideButton.vue';

  export default {
    name: 'SystemsTable',
    data: function() {
      return {
        sForm: {
          system_role: '',
          rules_followed: '',
          data_objects: []
        },

        // Systems
        tableItems: [],
        selectedSystem: [],
        viewSModal: false,
        selectedSSort: '',
        selectedSDirection: '',
        systemItems: [],
        sLoading: true,

        // Pagination
        perPageOptions: [
          { name: '10', value: '10' },
          { name: '20', value: '20' },
          { name: '50', value: '50' },
          { name: '100', value: '100' }
        ],
        perPage: '',
        selectedPerPage: "10",
        currentPage: 1,
        totalRows: 0,

        error: '',
        loadingItems: false,
        itemToDelete: {},
        itemObject: {},
        incident: {},
        selectedP: [],
        showSDescription: false,
        buttonDisabled: false,
        selectedItem: {},
        showTagsModal: false,
        showUsersModal: false,
        myGuides: []
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      }
    },
    components: {
      Avatar,
      SystemViewModal,
      ShowMoreModal,
      ActivityCreateForm,
      GuideButton
    },
    computed: {
      getClient() {
        return this.$store.state.client
      },
      rows() {
        // Use computed value for rows because of dynamic update
        return this.totalRows
      },
      getStorePerPage() {
        return this.$store.state.perPage
      },
      getPerPage() {
        let page = this.currentPage;
        let selectedFrom = (page-1) * Number(this.selectedPerPage) + 1;
        let selectedTo = (page-1) * Number(this.selectedPerPage) + Number(this.selectedPerPage);
        let selectedOf = this.totalRows;

        if(selectedTo > selectedOf){
          selectedTo = selectedOf
        }

        return selectedFrom + " - " + selectedTo + " " + this.$t('system.of') + " " + selectedOf
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
        this.loadP();
      },
      object: function (value) {
        this.incident = value;
      }
    },
    methods: {
      pageChanged(page) {
        // Load Third parties when page changed listener is triggered
        this.loadSystems(page)
      },
      perPageChanged(perPage) {
        let self = this;

        self.selectedPerPage = perPage.value
        this.$store.commit('storePerPage', this.selectedPerPage);
        // Load Third parties when page changed listener is triggered
        this.loadSystems(1, perPage.value)
      },
      perPageRemoved() {
        let self = this;
        // Load Third parties when page changed listener is triggered
        self.selectedPerPage = self.perPageOptions[0].value
        this.loadSystems(1, self.selectedPerPage)

      },
      showCreateSModal() {
        this.$refs['add-system'].show()
      },
      hideCreateSModal() {
        this.error = ''
        this.selectedSystem = []
        this.$refs['add-system'].hide()
      },
      showConfirmDeleteModal(object) {
        this.itemToDelete = object
        this.$refs['delete-system'].show()
      },
      hideConfirmDeleteModal() {
        this.itemToDelete = {}
        this.$refs['delete-system'].hide()
      },
      confirmDeleteSystem(object) {
        let self = this;
        piincHttp.delete('incident-categories/' + this.object.slug + '/systems/' + object.id).then(function() {
          self.hideConfirmDeleteModal();
          self.loadSystems();
          // Trigger toastr message after modal close
          self.$toastr('success', self.$t('maintenance.incidents.incident_categories.systems.delete_success'))
        }, function() {});
      },
      sFormSubmit(evt) {
        this.buttonDisabled = true;
        let formData = new FormData();

        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        if (this.selectedSystem && this.selectedSystem.id) {
          formData.append("system_id", this.selectedSystem.id);

          piincHttp.post('incident-categories/' + this.$route.params.slug + '/systems', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function(response) {
            self.$toastr('success', self.$t('maintenance.incidents.incident_categories.systems.create_success'))
            self.hideCreateSModal();
            self.loadSystems();
            self.$emit('updated');
            self.buttonDisabled = false;
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
            }
          })
        } else {
          this.buttonDisabled = false;
          if (!this.selectedSystem || (this.selectedSystem && !this.selectedSystem.id)) {
            this.error +=  ' ' + this.$t('system.system_error_message')
          }
        }

      },
      getGuide(code) {
        let existingGuide = _.find(this.myGuides, function(object) {
          return object.scope == code && object.type == 'Incident' && object.enabled
        });
        return existingGuide
      },
      loadGuides(code) {
        let self = this;
        let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

        piincHttp.get('guides', { params:
          {
            per_page: 1,
            language: language,
            type: 'Incident',
            scope: code,
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.myGuides.push(response.data.items[0])
          }
        }, function() {});
      },
      loadSystems() {
        let self = this;
        piincHttp.get('incident-categories/' + this.$route.params.slug + '/systems', { params:
          {
            client: this.getClient.slug
          }
        }).then(function(response) {
          self.tableItems = response.data;
          self.sLoading = false;
        }, function() {});
      },
      loadP(query) {
        let self = this;
        piincHttp.get('systems', { params:
          {
            client: this.getClient.slug,
            per_page: 100,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.systemItems = response.data.items
          if (self.systemItems && !self.systemItems.length && !query) {
            self.systemItems.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      }
    },
    created () {
      this.incident = this.object;
      if (this.getClient && this.getClient.slug) {
        this.loadP();
      }
      // Table items
      this.loadSystems();
      this.loadGuides('update_systems');
    }
  }
</script>
