<template>
  <div>

    <div v-if="!show404 && !show403">
      <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>

      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-7">
              <!-- <router-link
                  :to="{ name: 'projects.view', params: { slug: incident.project.slug }}"
                  class="router-link-active"
                  v-if="incident.project && incident.project.id && incident.control_type == 'one time'"
              >
                <font-awesome-icon icon="chevron-circle-left" size="lg" class="router-link-active"/>
                {{ incident.project.title }}
              </router-link> -->
              <h4 qid="p-view-page-title" class="mt-2 mb-3">
                {{ incident.title }}
              </h4>
              <span v-if="incident.users && incident.users.length" class="mr-2">
                {{ $t('table.owners') }}:
              </span>
              <span class="mr-1" v-for="user in incident.users" qid="incident-users-list">
                <Avatar :object="user" size="xs"></Avatar>
              </span>
              <span v-if="incident.tags_translated && incident.tags_translated.length" class="mr-2 ml-2">
                {{ $t('table.tags') }}:
              </span>
              <span v-if="incident.tags_translated && incident.tags_translated.length" qid="incident-tags-list">
                <span class="badge" v-for="tag in getReducedTags(incident.tags_translated)" :title="tag.name_translated">
                  <font-awesome-icon icon="tag"/>
                  {{ tag.name_translated | truncate(35) }}
                </span>
                <span v-if="incident.tags_translated.length > 3"
                      class="badge cursor-pointer"
                      @click="openTagsModal(incident)"
                >
                  <font-awesome-icon icon="tags"/>
                  + {{ incident.tags_translated.length - 3 }}
                </span>
              </span>
              <span v-if="incident.scope" class="mr-2 ml-2">
                {{ $t('table.scope') }}:
              </span>
              <span v-if="incident.type" class="mr-2 ml-2">
                {{ $t('table.type') }}:
              </span>
              <span class="mr-1" qid="incident-tags-list">
                <span class="badge">
                  <span v-if="incident.type == 'update'">
                    {{ $t('projects.view.update_label') }}
                  </span>
                  <span v-if="incident.type == 'validation'">
                    {{ $t('projects.view.validation_label') }}
                  </span>
                  <span v-if="incident.type == 'control'">
                    {{ $t('projects.view.control_label') }}
                  </span>
                  <span v-if="incident.type == 'security_measure'">
                    {{ $t('projects.view.security_measure_label') }}
                  </span>
                  <span v-if="incident.type == 'risk_assessment' && incident.assessment">
                    {{ $t('maintenance.assessments.type.' + $options.filters.slugify(incident.assessment.type)) }}
                  </span>
                </span>
              </span>

            </div>
            <div class="col-md-5 text-right" qid="view-s-modified">
              <div class="mb-4">
                <span v-if="incident.control_type == 'one time'">
                  <span v-if="incident.status === 'completed'" class="badge-pill-small badge-success mr-1">
                    <font-awesome-icon icon="check" size="xs"/>
                  </span>
                  <span v-if="incident.status === 'pending'" class="pr-1">
                    <font-awesome-icon icon="exclamation-triangle" class="risk-6"/>
                  </span>
                  <span v-if="incident.status === null" class="pr-1">
                    <font-awesome-icon icon="exclamation-triangle" class="risk-6"/>
                    {{ $t('system.pending') }}
                  </span>
                  <span v-if="incident.status === 'in progress'" class="pr-1">
                    <font-awesome-icon icon="exclamation-triangle" class="risk-1"/>
                  </span>
                  <span v-if="incident.status === 'closed'" class="pr-1">
                    <font-awesome-icon icon="exclamation-triangle" class="risk-12"/>
                  </span>
                  {{ incident.status | capitalize }}
                </span>
                <span v-if="incident.control_type == 'continuous'">
                  <span v-if="!incident.is_active_child_project" class="pr-1">
                    <font-awesome-icon icon="exclamation-triangle" class="risk-6"/>
                    {{ $t('system.pending') }}
                  </span>
                  <span v-if="incident.is_active_child_project" class="pr-1">
                    <font-awesome-icon icon="exclamation-triangle" class="risk-1"/>
                    {{ $t('system.in_progress') }}
                  </span>
                </span>
                <span class="h6 ml-3">
                  <span class="mr-3 btn btn-success cursor-pointer"
                        @click="showConfirmCompleteModal(incident)"
                        qid="complete-project"
                        v-if="incident.control_type == 'one time' && incident.type != 'risk_assessment' && (incident.status == null || incident.status == 'pending' || incident.status == 'in progress') && (incident.task_count == 0 || incident.task_count == undefined)"
                  >
                    {{ $t('projects.button.complete_project') }}
                  </span>
                  <span class="mr-3 btn btn-success cursor-pointer"
                        @click="showConfirmCompleteModal(incident)"
                        qid="complete-project"
                        v-if="incident.control_type == 'one time' && incident.type == 'risk_assessment' && (incident.status == null || incident.status == 'pending' || incident.status == 'in progress') && (incident.task_count == 0 || incident.task_count == undefined) && ( (!incident.system_impact_assessment) || (incident.system_impact_assessment && (incident.system_risk_count == 0 || incident.system_risk_count == undefined)))"
                  >
                    {{ $t('projects.button.complete_project') }}
                  </span>
                  <span class="mr-3 btn btn-light cursor-pointer"
                        @click="showConfirmReopenModal(incident)"
                        qid="close-project"
                        v-if="incident.control_type == 'one time' && (incident.status == 'closed' || incident.status == 'completed')"
                  >
                    {{ $t('projects.button.reopen_project') }}
                 </span>
                  <span class="mr-3 btn btn-light cursor-pointer"
                        @click="showConfirmCloseModal(incident)"
                        qid="close-project"
                        v-if="incident.control_type == 'one time' && (incident.status == null || incident.status == 'pending' || incident.status == 'in progress' || incident.status == 'completed')"
                  >
                    {{ $t('projects.button.close_project') }}
                  </span>

                  <ProjectEditForm
                      :object="incident"
                      :edit="edit"
                      @reloadContent="reloadContent"
                      v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], incident) && incident.status != 'closed' && incident.status != 'completed'"
                  ></ProjectEditForm>
                  <span class="btn btn-delete"
                        qid="delete-s-button"
                        v-if="isGranted([permissions.PROJECT_DELETE, permissions.PROJECT_DELETE_OWN], incident) && !incident.hasRunningProjects"
                        @click="showConfirmDeleteModal(incident)"
                  >
                    <font-awesome-icon icon="trash-alt"/>
                  </span>
                </span>
              </div>
              <div>
                <span>{{ $t('table.last_updated') }}</span>
                    <span class="ml-2 text-muted small" qid="view-s-modified-date">
                  {{ incident.updated_at | formatDate }}
                </span>
                <Avatar v-if="incident.updated_by"
                        :object="incident.updated_by"
                        size="xs"
                        class="ml-3 mr-2"
                ></Avatar>
                <span v-if="incident.updated_by" qid="view-s-modified-by">
                  {{ incident.updated_by.name }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-tabs class="mt-3" v-model="tabIndex">

        <!-- Basic info tab -->
        <b-tab :title="$t('projects.tab.basic_info')" active @click="basicTabClicked">
          <br>
          <div class="card" qid="basic-info-section">
            <div class="card-body">
              <div class="content-view-body">
                <div>
                  <h5 class="h5">
                    <span class="float-right btn-sm btn-edit"
                          @click="showDescriptionInputFunction"
                          qid="incident-edit-description"
                          v-if="!showDescriptionInput && isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], incident) && incident.status != 'closed' && incident.status != 'completed'"
                    >
                      <font-awesome-icon icon="pen"/>
                    </span>
                    <span class="float-right" v-if="showDescriptionInput">
                      <div class="text-right">
                        <b-button class="btn-sm inline-edit-button"
                                  variant="secondary"
                                  @click="cancelDescriptionForm"
                                  qid="incident-edit-button-cancel"
                        >
                          {{ $t('activities.create.cancel') }}
                        </b-button>
                        <b-button class="ml-3 btn-sm inline-edit-button"
                                  variant="success"
                                  @click="formDescriptionSubmit"
                                  qid="incident-edit-button-submit"
                        >
                          {{ $t('activities.create.submit') }}
                        </b-button>
                      </div>
                    </span>
                    {{ $t('activities.description_label') }}
                  </h5>

                  <div v-if="showDescriptionInput">
                    <b-form-textarea
                      qid="edit-tp-form-description"
                      v-model="activityEditDescription"
                      rows="6"
                      :placeholder="$t('maintenance.third_parties.create.description_placeholder')"
                    ></b-form-textarea>
                  </div>

                  <div v-if="!showDescriptionInput" class="section-container">
                    <div v-if="incident.description && incident.description.length > descriptionLength">
                      <p v-if="showFullDescription" class="text-formatted">{{ incident.description }}</p>
                      <p v-if="!showFullDescription" class="text-formatted">{{ incident.description | truncate(descriptionLength) }}</p>
                      <div class="text-center mt-3" @click="showFullDescription=!showFullDescription">
                        <span class="cursor-pointer" v-if="!showFullDescription">
                          <span class="mr-2">
                            {{ $t('system.view_more') }}
                          </span>
                          <font-awesome-icon icon="chevron-down"/>
                        </span>
                        <span class="cursor-pointer" v-if="showFullDescription">
                          <span class="mr-2">
                            {{ $t('system.view_less') }}
                          </span>
                          <font-awesome-icon icon="chevron-up"/>
                        </span>
                      </div>
                    </div>
                    <p v-if="incident.description && incident.description.length <= descriptionLength"
                       class="text-formatted"
                    >{{ incident.description }}</p>
                    <p v-if="!incident.description" class="text-muted">
                      {{ $t('system.missing_input') }}
                    </p>
                  </div>
                  <br>
                </div>
              </div>
            </div>
          </div>

        </b-tab>


        <!-- Relations tab -->
        <b-tab :title="$t('maintenance.incidents.tab.relations')"
               class="mt-3"
               @click="relationsTabClicked"
        >
          <div v-if="showRelationsTab">
            <b-tabs class="menu-smaller">

              <!-- Related incidents tab -->
              <b-tab :title="$t('maintenance.incidents.tab.incidents')" class="mt-3">
                <!-- <IncidentsTable :object="incident"></IncidentsTable> -->
              </b-tab>

              <!-- Risk scenarios tab -->
              <b-tab :title="$t('maintenance.incidents.tab.risk_scenarios')" class="mt-3">
                <RiskScenariosTable :object="incident"></RiskScenariosTable>
              </b-tab>

              <!-- Systems tab -->
              <b-tab :title="$t('maintenance.incidents.tab.systems')" class="mt-3">
                <SystemsTable :object="incident"></SystemsTable>
              </b-tab>

            </b-tabs>
          </div>
        </b-tab>

        <!-- Tasks tab -->
        <b-tab :title="$t('projects.tab.tasks')" class="mt-3" v-if="incident.control_type == 'one time'" @click="tasksTabClicked">
          <template slot='title'>
            {{$t('projects.tab.tasks')}}
            <font-awesome-icon
                    icon="exclamation-triangle"
                    v-b-tooltip.hover
                    :title="$t('tasks.messages.unresolved_tasks')"
                    class="risk-6"
                    v-if="incident.task_count != 0 && incident.task_count != undefined"
            />
          </template>
          <div v-if="showTasksTab">
            <TasksTable :object="incident" @updated="updateActivity(false)"></TasksTable>
          </div>
        </b-tab>


        <!-- Resolution tab -->
        <b-tab :title="$t('maintenance.incidents.tab.resolution')"
               class="mt-3"
               @click="resolutionTabClicked"
        >
          <div v-if="showResolutionTab">

          </div>
        </b-tab>


        <!-- History tab -->
        <b-tab :title="$t('projects.tab.history')" class="mt-3" @click="historyTabClicked">
          <div v-if="showHistoryTab">
            <ProjectHistory :object="incident"></ProjectHistory>
          </div>
        </b-tab>

      </b-tabs>


      <!-- Re-assign modal -->
      <b-modal ref="re-assign" hide-footer @hide="hideEditUsersModal">
        <div class="d-block text-center">
          <h4 qid="update-tp-title">
            {{ $t('tasks.re_assign.title') }}
          </h4>
        </div>
        <b-alert v-if="error.length"
                 show
                 variant="danger"
                 qid="create-p-error"
        >
          {{ error }}
        </b-alert>
        <b-form @submit="reassignFormSubmit" qid="create-p-form" v-if="selectedTask && selectedTask.model">
          <div class="form-container">
            <b-form-group>
              <label qid="create-p-users-label">
                {{ $t('projects.create.owners_label') }}
              </label>
              <multiselect
                  v-model="selectedAssignUsers"
                  :options="allUsers"
                  :multiple="true"
                  :taggable="false"
                  track-by="slug"
                  :select-label="$t('system.dropdown_select_label')"
                  :selected-label="$t('system.dropdown_selected_label')"
                  :deselect-label="$t('system.dropdown_deselect_label')"
                  :placeholder="$t('projects.create.owners_placeholder')"
                  label="name"
                  qid="create-p-users-select"
              >
                <template slot="tag" slot-scope="props">
                  <span class="mr-3 user-tag">
                    <Avatar :object="props.option" size="xs"></Avatar>
                    <span class="ml-2">{{ props.option.name }}</span>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <Avatar :object="props.option" size="xs"></Avatar>
                  <span class="ml-2">
                    {{ props.option.name }}
                  </span>
                </template>
              </multiselect>
            </b-form-group>
          </div>
          <div class="text-right">
            <b-button class="mt-3"
                      variant="secondary"
                      @click="hideEditUsersModal"
                      qid="create-p-cancel-button"
            >
              {{ $t('projects.create.cancel') }}
            </b-button>
            <b-button type="submit"
                      class="mt-3 ml-3"
                      variant="success"
                      :disabled="buttonDisabled"
                      qid="create-p-submit-button"
            >
              <span v-if="buttonDisabled" class="mr-1">
                <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
              </span>
              {{ $t('projects.create.submit') }}
            </b-button>
          </div>
        </b-form>
      </b-modal>


      <b-modal ref="view-task-modal" hide-footer @hide="hideViewTaskModal">
        <div class="d-block text-center">
          <h4 qid="update-tp-title">
            {{ $t('tasks.modal.view_title') }}
          </h4>
        </div>
        <b-form qid="create-p-form" v-if="selectedTask && selectedTask.model">
          <div class="form-container">
            <b-form-group class="mb-4">
              <div v-if="selectedTask.scope">
                <font-awesome-icon icon="shield-alt" size="lg" class="mr-2"/>
                {{ selectedTask.scope.name }}
              </div>
              <div v-if="selectedTask.model && selectedTask.project.type != 'security_measure'" class="mt-3">
                <span v-if="selectedTask.model">
                  <font-awesome-icon icon="desktop" size="lg" class="mr-2" v-if="selectedTask.model.who_am_i == 'App\\System\\Models\\System'"/>
                  <font-awesome-icon icon="cubes" size="lg" class="mr-2" v-if="selectedTask.model.who_am_i == 'App\\Activity\\Models\\Activity'"/>
                  <font-awesome-icon icon="cube" size="lg" class="mr-2" v-if="selectedTask.model.who_am_i == 'App\\Process\\Models\\Process'"/>
                  <font-awesome-icon icon="file-signature" size="lg" class="mr-2" v-if="selectedTask.model.who_am_i == 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty'"/>
                  <font-awesome-icon icon="file-invoice" size="lg" class="mr-2" v-if="selectedTask.model.who_am_i == 'App\\Maintenance\\Policy\\Models\\Policy'"/>
                </span>
                {{ selectedTask.model.name }}
              </div>
            </b-form-group>
            <b-form-group>
              <label qid="create-p-users-label">
                {{ $t('tasks.modal.project_message_label') }}
              </label>
              <div v-if="selectedTask.project.message && selectedTask.project.message.length > 300">
                <p v-if="showFullProjectMessage" class="text-formatted">
                  {{ selectedTask.project.message }}
                </p>
                <p v-if="!showFullProjectMessage" class="text-formatted">
                  {{ selectedTask.project.message | truncate(300) }}
                </p>
                <div class="text-center mt-3" @click="showFullProjectMessage=!showFullProjectMessage">
                  <span class="cursor-pointer" v-if="!showFullProjectMessage">
                    <span class="mr-2">
                      {{ $t('system.view_more') }}
                    </span>
                    <font-awesome-icon icon="chevron-down"/>
                  </span>
                  <span class="cursor-pointer" v-if="showFullProjectMessage">
                    <span class="mr-2">
                      {{ $t('system.view_less') }}
                    </span>
                    <font-awesome-icon icon="chevron-up"/>
                  </span>
                </div>
              </div>
              <p v-if="selectedTask.project.message && selectedTask.project.message.length <= 300"
                 class="text-formatted"
              >
                {{ selectedTask.project.message }}
              </p>
            </b-form-group>
            <b-form-group v-if="selectedTask && selectedTask.scope">
              <label qid="create-p-users-label">
                {{ $t('tasks.modal.generic_control_description_label') }}
              </label>
              <div v-if="selectedTask.scope.description && selectedTask.scope.description.length > 300">
                <p v-if="showFullGenericControlDescription" class="text-formatted">
                  {{ selectedTask.scope.description }}
                </p>
                <p v-if="!showFullGenericControlDescription" class="text-formatted">
                  {{ selectedTask.scope.description | truncate(300) }}
                </p>
                <div class="text-center mt-3" @click="showFullGenericControlDescription=!showFullGenericControlDescription">
                  <span class="cursor-pointer" v-if="!showFullGenericControlDescription">
                    <span class="mr-2">
                      {{ $t('system.view_more') }}
                    </span>
                    <font-awesome-icon icon="chevron-down"/>
                  </span>
                  <span class="cursor-pointer" v-if="showFullGenericControlDescription">
                    <span class="mr-2">
                      {{ $t('system.view_less') }}
                    </span>
                    <font-awesome-icon icon="chevron-up"/>
                  </span>
                </div>
              </div>
              <p v-if="selectedTask.scope.description && selectedTask.scope.description.length <= 300"
                 class="text-formatted"
              >
                {{ selectedTask.scope.description }}
              </p>
            </b-form-group>
            <b-form-group>
              <label qid="create-p-users-label">
                {{ $t('tasks.modal.status_label') }}
              </label>
              <p>
                <span v-if="selectedTask.completion_status == 'pass'" class="badge badge-success pl-4 pr-4">
                  {{ $t('system.passed') }}
                </span>
                <span v-if="selectedTask.completion_status == 'fail'" class="badge badge-danger pl-4 pr-4">
                  {{ $t('system.failed') }}
                </span>
              </p>
            </b-form-group>
            <b-form-group>
              <label qid="create-p-users-label">
                {{ $t('tasks.modal.remarks_label') }}
              </label>
              <p>{{ selectedTask.remarks }}</p>
            </b-form-group>
            <div class="mt-2">
              <label>
                {{ $t('maintenance.policies.create.attachments') }}
              </label>
              <br>
              <p v-for="file in selectedTask.media">
                <span class="mr-3 float-right text-muted cursor-pointer"
                      @click="downloadMedia(file)"
                      qid="download-media"
                >
                  <font-awesome-icon icon="cloud-download-alt" />
                </span>
                <a href="#" @click="downloadMedia(file)" qid="create-p-media-item">
                  {{ file.file_name }}
                </a>
              </p>
            </div>
            <div class="mt-4">
              <label>
                {{ $t('maintenance.policies.create.links_label') }}
              </label>
              <div v-if="selectedTask.links">
                <p v-for="link in selectedTask.links" >
                  <a :href="link.url" target="_blank" qid="create-p-link-item">
                    {{ link.name ? link.name : link.url }}
                  </a>
                </p>
              </div>
              <br>
            </div>
          </div>
          <div class="text-right">
            <b-button @click="hideViewTaskModal"
                      class="ml-3"
                      variant="success"
                      qid="create-p-submit-button"
            >
              {{ $t('system.close') }}
            </b-button>
          </div>
        </b-form>
      </b-modal>

      <!-- Delete Project modal template -->
      <b-modal ref="delete-activity" hide-footer>
        <div class="d-block text-center">
          <h4 qid="delete-s-title">
            {{ $t('activities.delete.title') }}
          </h4>
        </div>
        <h5 class="text-center">
          {{ $t('activities.delete.description') }} {{ itemToDelete.name }}
        </h5>
        <h5 class="text-center">
          {{ $t('activities.delete.are_you_sure') }}
        </h5>
        <div class="text-center">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideConfirmDeleteModal"
                    qid="delete-s-cancel-button"
          >
            {{ $t('activities.delete.cancel') }}
          </b-button>
          <b-button @click="confirmDelete(itemToDelete)"
                    class="mt-3 ml-3"
                    variant="success"
                    qid="delete-s-submit-button"
                    v-if="isGranted([permissions.PROJECT_DELETE, permissions.PROJECT_DELETE_OWN], incident)"
          >
            {{ $t('activities.delete.submit') }}
          </b-button>
        </div>
      </b-modal>

      <!-- Complete Project modal template -->
      <b-modal ref="complete-project" hide-footer>
        <div class="d-block text-center">
          <h4 qid="delete-s-title">
            {{ $t('projects.confirm.complete') }}
          </h4>
        </div>
        <h5 class="text-center">
          {{ $t('projects.confirm.complete_description') }}<br />{{ itemToChangeStatus.title }}
        </h5>
        <div class="text-center">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideConfirmCompleteModal"
                    qid="delete-s-cancel-button"
          >
            {{ $t('activities.delete.cancel') }}
          </b-button>
          <b-button @click="completeProject()"
                    class="mt-3 ml-3"
                    variant="success"
                    qid="delete-s-submit-button"
                    v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], itemToChangeStatus)"
          >
            {{ $t('activities.delete.submit') }}
          </b-button>
        </div>
      </b-modal>

      <!-- Close Project modal template -->
      <b-modal ref="close-project" hide-footer>
        <div class="d-block text-center">
          <h4 qid="delete-s-title">
            {{ $t('projects.confirm.close') }}
          </h4>
        </div>
        <h5 class="text-center">
          {{ $t('projects.confirm.close_description') }}<br />{{ itemToChangeStatus.title }}
        </h5>
        <div class="text-center">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideConfirmCloseModal"
                    qid="delete-s-cancel-button"
          >
            {{ $t('activities.delete.cancel') }}
          </b-button>
          <b-button @click="closeProject()"
                    class="mt-3 ml-3"
                    variant="success"
                    qid="delete-s-submit-button"
                    v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], itemToChangeStatus)"
          >
            {{ $t('activities.delete.submit') }}
          </b-button>
        </div>
      </b-modal>

      <!-- Reopen Project modal template -->
      <b-modal ref="reopen-project" hide-footer>
        <div class="d-block text-center">
          <h4 qid="delete-s-title">
            {{ $t('projects.confirm.reopen') }}
          </h4>
        </div>
        <h5 class="text-center">
          {{ $t('projects.confirm.reopen_description') }}<br />{{ itemToChangeStatus.title }}
        </h5>
        <div class="text-center">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideConfirmReopenModal"
                    qid="delete-s-cancel-button"
          >
            {{ $t('activities.delete.cancel') }}
          </b-button>
          <b-button @click="reopenProject()"
                    class="mt-3 ml-3"
                    variant="success"
                    qid="delete-s-submit-button"
                    v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], itemToChangeStatus)"
          >
            {{ $t('activities.delete.submit') }}
          </b-button>
        </div>
      </b-modal>

      <ConfirmRemoveAttachment
          :object="selectedFile"
          :show="showDeleteAttachmentModal"
          type="file"
          @closeConfirmModal="closeDeleteAttachmentModal"
      ></ConfirmRemoveAttachment>

      <ConfirmRemoveAttachment
          :object="selectedLink"
          :show="showDeleteLinkModal"
          type="link"
          @closeConfirmModal="closeDeleteLinkModal"
      ></ConfirmRemoveAttachment>

      <ShowMoreModal
          :items="selectedItem.tags_translated"
          :show="showTagsModal"
          @closeViewModal="closeTagsModal"
          type="tags"
      ></ShowMoreModal>
    </div>


    <div v-if="show404">
      <page404></page404>
    </div>

    <div v-if="show403">
      <page403></page403>
    </div>

  </div>
</template>

<script>
import piincHttp from '@/util/configureAxios.js'
import {config} from '@/config.js'
import ProjectEditForm from '@/views/Projects/ProjectEditForm.vue';
import Avatar from '@/components/Avatar.vue';
import ProjectHistory from '@/views/Projects/ProjectHistory.vue';
import SystemsTable from '@/views/Incidents/IncidentSystemsTable.vue';
// import IncidentsTable from '@/views/Incidents/IncidentsTable.vue';
import RiskScenariosTable from '@/views/Incidents/IncidentRiskScenariosTable.vue';
import ConfirmRemoveAttachment from '@/components/ConfirmRemoveAttachment.vue';
import TasksTable from '@/views/Projects/TasksTable.vue';
import ObservationsTable from '@/views/Projects/ObservationsTable.vue';
import ShowMoreModal from '@/components/ShowMoreModal.vue';
import page404 from '@/components/404block.vue';
import page403 from '@/components/403block.vue';


export default {
  name: 'IncidentView',
  data: function() {
    return {
      breadcrumbItems: [
        {
          text: this.$t('dashboard.title'),
          to: '/'
        }
      ],
      incident: {},
      edit: this.$route.params.edit,
      selectedSort: '',
      selectedDirection: '',
      lbTableItems: [],
      lbLoading: true,
      showInput: false,

      itemToDelete: {},
      itemToChangeStatus: {},
      users: [],
      error: '',
      lbForm: {
        name: '',
        article_reason: '',
        links: []
      },
      inputLink: '',
      selectedFiles: [],
      selectedFile: {},
      selectedLink: {},
      showDeleteAttachmentModal: false,
      showDeleteLinkModal: false,
      showDescriptionInput: false,
      activityEditDescription: '',
      descriptionLength: config.descriptionLength,
      showFullDescription: false,
      // Tabs
      showActivityTab: false,
      showRelationsTab: false,
      showControlTab: false,
      showSystemTab: false,
      showAssessmentTab: false,
      showThirdPartyTab: false,
      showPolicyTab: false,
      showScheduleTab: false,
      showTasksTab: false,
      showSystemRiskTab: false,
      showResolutionTab: false,
      showHistoryTab: false,

      buttonDisabled: false,
      tabIndex: 0,
      myGuides: [],
      open_tasks: [],
      closed_tasks: [],
      showStatusForm: false,
      options: [
        { name: this.$t('system.pending'), value: 'pending' },
        { name: this.$t('system.in_progress'), value: 'in progress' },
        { name: this.$t('system.completed'), value: 'completed' },
        { name: this.$t('system.closed'), value: 'closed' }
      ],
      selectedStatus: [],
      selectedTask: [],
      selectedAssignUsers: [],
      itemObject: {},
      allUsers: [],
      selectedItem: {},
      showTagsModal: false,
      show404: false,
      show403: false,
    }
  },
  computed: {
    getClient() {
      return this.$store.state.client
    },
    getFiles() {
      return this.selectedFiles
    },
    getUser() {
      return this.$store.state.user
    },
  },
  watch: {
    // Watch when client changes in store and trigger api reload
    getClient: function (newValue, oldValue) {
      if (newValue && oldValue && newValue.slug && oldValue.slug && newValue.slug !== oldValue.slug) {
        this.goToList()
      } else {
        this.loadContent(true);
      }
    }
  },
  components: {
    ProjectEditForm,
    Avatar,
    ProjectHistory,
    SystemsTable,
    RiskScenariosTable,
    ConfirmRemoveAttachment,
    TasksTable,
    ObservationsTable,
    ShowMoreModal,
    page403,
    page404
  },
  methods: {
    openTagsModal(object) {
      this.selectedItem = object
      this.showTagsModal = true
    },
    closeTagsModal() {
      this.selectedItem = {}
      this.showTagsModal = false
    },
    getReducedTags(tags) {
      let reducedTags = []
      if (tags.length > 3) {
        reducedTags = _.cloneDeep(tags);
        reducedTags = reducedTags.slice(0, 3);
      } else {
        reducedTags = tags;
      }
      return reducedTags;
    },
    goToList() {
      this.$router.push('/projects')
    },
    resetFile() {
      let imagefile = document.querySelector('#file');
      imagefile.value = '';
    },
    loadContent(breadcrumbPush) {
      let self = this;
      piincHttp.get('projects/' + this.$route.params.slug, { params:
        {
          client: this.getClient.slug
        }
      }).then(function(response) {
        self.incident = response.data;

        if (breadcrumbPush) {
          // Push Third party name in breadcrumb array
          self.breadcrumbItems.push({text: self.incident.title, active: true});
        }

      }, function(error) {
        if (error.status == 404) {
          self.show404 = true
        }
        if (error.status == 403) {
          self.show403 = true
        }
      });
    },
    showViewTaskModal(object) {
      let self = this;
      this.error = '';

      this.selectedTask = object
      this.$refs['view-task-modal'].show()
    },
    hideViewTaskModal() {
      let self = this;
      this.error = '';

      this.selectedTask = {}
      this.$refs['view-task-modal'].hide()
    },
    updateActivity() {
      this.loadContent();
    },
    historyTabClicked() {
      this.showHistoryTab = true;
      this.showSystemTab = false;
      this.showThirdPartyTab = false;
      this.showPolicyTab = false;
      this.showRelationsTab = false;
      this.showActivityTab = false;
      this.showTasksTab = false;
      this.showSystemRiskTab = false;
      this.showResolutionTab = false;
    },
    basicTabClicked() {
      this.showHistoryTab = false;
      this.showSystemTab = false;
      this.showThirdPartyTab = false;
      this.showPolicyTab = false;
      this.showRelationsTab = false;
      this.showActivityTab = false;
      this.showTasksTab = false;
      this.showSystemRiskTab = false;
      this.showResolutionTab = false;
      this.showScheduleTab = false;
    },
    relationsTabClicked() {
      let self = this
      this.showHistoryTab = false;
      this.showRelationsTab = true;
      this.showTasksTab = false;
      this.showSystemRiskTab = false;
      this.showResolutionTab = false;
      this.showScheduleTab = false;
    },
    tasksTabClicked() {
      this.showTasksTab = true;
      this.showSystemRiskTab = false;
      this.showResolutionTab = false;
      this.showScheduleTab = false;
    },
    resolutionTabClicked() {
      this.showResolutionTab = true;
      this.showTasksTab = false;
      this.showSystemRiskTab = false;
      this.showScheduleTab = false;
    },
    handleCloseViewPModal () {
      this.loadPolicies();
      this.viewPolicyModal = false;
      this.selectedPolicy = []
    },
    showDescriptionInputFunction() {
      this.showDescriptionInput = true;
      this.activityEditDescription = this.incident.description;
    },
    formDescriptionSubmit() {
      let self = this;
      piincHttp.put('projects/' + this.$route.params.slug, {
        description: this.activityEditDescription
      }).then(function(response) {
        self.incident = response.data;
        self.showDescriptionInput = false;
        self.$toastr('success', self.$t('projects.update.success'))
      }, function(error) {
        if (error.status !== 200) {
          self.error = error.data.message;
        }
      })
    },
    completeProject() {
      let self = this;
      piincHttp.put('projects/' + this.$route.params.slug, {
        status: "completed"
      }).then(function(response) {
        self.incident = response.data;
        self.$refs['complete-project'].hide()
        self.$toastr('success', self.$t('projects.update.success'))
      }, function(error) {
        if (error.status !== 200) {
          self.error = error.data.message;
        }
      })
    },
    closeProject() {
      let self = this;
      piincHttp.put('projects/' + this.$route.params.slug, {
        status: "closed"
      }).then(function(response) {
        self.incident = response.data;
        self.$refs['close-project'].hide()
        self.$toastr('success', self.$t('projects.update.success'))
      }, function(error) {
        if (error.status !== 200) {
          self.error = error.data.message;
        }
      })
    },
    reopenProject() {
      let self = this;
      piincHttp.put('projects/' + this.$route.params.slug, {
        status: "in progress"
      }).then(function(response) {
        self.incident = response.data;
        self.$refs['reopen-project'].hide()
        self.$toastr('success', self.$t('projects.update.success'))
      }, function(error) {
        if (error.status !== 200) {
          self.error = error.data.message;
        }
      })
    },
    cancelDescriptionForm() {
      this.showDescriptionInput = false;
      this.activityEditDescription = this.incident.description;
    },
    openViewPolicyModal(object) {
      this.selectedPolicy = object
      this.viewPolicyModal = true
    },
    reloadContent(object) {
      if (this.tabIndex == 1) {
        this.tabIndex = 0
      }
      // If slug is not changed, just reload content
      if (object.slug == this.incident.slug) {
        // Remove last item in breadcrumbs, because it will be added again on load content
        this.breadcrumbItems.pop();
        this.breadcrumbItems.pop();
        this.loadContent(true);
      } else {
        // If slug changed, reload state with new param
        this.$router.push({ name: 'projects.view', params: { slug: object.slug }})
      }
    },
    getUserAvatar(user){
      if (user && user.avatar) {
        return user.avatar;
      } else {
        return require('@/assets/avatar.png');
      }
    },
    showConfirmDeleteModal(object) {
      this.itemToDelete = object
      this.$refs['delete-activity'].show()
    },
    showConfirmCompleteModal(object) {
      this.itemToChangeStatus = object
      this.$refs['complete-project'].show()
    },
    showConfirmCloseModal(object) {
      this.itemToChangeStatus = object
      this.$refs['close-project'].show()
    },
    showConfirmReopenModal(object) {
      this.itemToChangeStatus = object
      this.$refs['reopen-project'].show()
    },
    hideConfirmReopenModal() {
      this.itemToChangeStatus = {}
      this.$refs['reopen-project'].hide()
    },
    hideConfirmDeleteModal() {
      this.itemToDelete = {}
      this.$refs['delete-activity'].hide()
    },
    hideConfirmCompleteModal() {
      this.itemToChangeStatus = {}
      this.$refs['complete-project'].hide()
    },
    hideConfirmCloseModal() {
      this.itemToChangeStatus = {}
      this.$refs['close-project'].hide()
    },
    confirmDelete(object) {
      let self = this;
      piincHttp.delete('projects/' + object.slug).then(function() {
        self.hideConfirmDeleteModal();
        self.goToList();
        // Trigger toastr message after modal close
        self.$toastr('success', self.$t('projects.delete.success'))
      }, function() {});
    },
    handleLink() {
      let self = this;
      let formData = new FormData();

      if (!this.inputLink.startsWith('http')) {
        this.inputLink = 'http://' + this.inputLink
      }

      formData.append("url", this.inputLink);
      formData.append("model_type", this.incident.who_am_i);
      formData.append("model_id", this.incident.id);

      piincHttp.post('links', formData).then(function() {
        // Remove last item in breadcrumbs, because it will be added again on load content
        self.breadcrumbItems.pop();
        self.$toastr('success', self.$t('maintenance.third_parties.link_add_success'))
        self.loadContent()
        self.inputLink = ''
        self.showInput = false
      }, function() {})
    },
    downloadMedia(object){
      piincHttp.get('documents/' + object.id + '/download', {responseType: 'blob'}).then(function(response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.download = object.file_name;

        link.click();
        link.remove()
        window.URL.revokeObjectURL(url);
      }, function() {});
    },
    openDeleteAttachmentModal(object) {
      this.selectedFile = object
      this.showDeleteAttachmentModal = true
    },
    closeDeleteAttachmentModal () {
      this.selectedFile = {}
      this.showDeleteAttachmentModal = false;
      // Remove last item in breadcrumbs, because it will be added again on load content
      this.breadcrumbItems.pop();
      this.loadContent();
    },
    openDeleteLinkModal(object) {
      this.selectedLink = object
      this.showDeleteLinkModal = true
    },
    closeDeleteLinkModal () {
      this.selectedLink = {}
      this.showDeleteLinkModal = false;
      // Remove last item in breadcrumbs, because it will be added again on load content
      this.breadcrumbItems.pop();
      this.loadContent();
    },
    onFileChange(file) {
      let files = file.target.files;
      let self = this;
      let formData = new FormData();
      let sizeError = false;

      for (var i = 0; i < files.length; i++) {
        // Equal to 10 MB
        if (files[i].size > 10485760) {
          sizeError = true;
        }
        formData.append('document[]', files[i]);
      }

      formData.append("name", "documents");
      formData.append("model_type", this.incident.who_am_i);
      formData.append("model_id", this.incident.id);

      if (sizeError) {
        this.error = this.$t('system.file_size')
      } else {
        piincHttp.post('documents', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(function() {
          // Remove last item in breadcrumbs, because it will be added again on load content
          self.breadcrumbItems.pop();
          self.$toastr('success', self.$t('maintenance.third_parties.media_add_success'))
          self.error = ''
          self.loadContent()
        }, function(error) {
          self.error = error.data.message;
          if (error.data.errors['document.0']) {
            self.error += ' ' + error.data.errors['document.0'][0]
          }
        })
      }
    },
    loadGuides() {
      let self = this;
      let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

      piincHttp.get('guides', { params:
        {
          per_page: 100,
          language: 'en',
          client: this.getClient.slug
        }
      }).then(function(response) {
        if (response.data.items.length) {
          self.myGuides = response.data.items
        }
      }, function() {});
    },
    sendReminders(object) {
      let self = this;
      // Call API for reminders
      piincHttp.get('projects/' + object.slug + '/reminders').then(function(response) {
        self.$toastr('success', self.$t('projects.create.project_reminders_success'))
      }, function() {});
    },
    getReducedUsers(users) {
      let reducedUsers = []
      if (users && users.length > 3) {
        reducedUsers = _.cloneDeep(users);
        reducedUsers = reducedUsers.slice(0, 3);
      } else {
        reducedUsers = users;
      }
      return reducedUsers;
    },
    formStatusSubmit() {
      // Keep the reference in a variable
      // to use it inside function called when promise returned
      let self = this;

      piincHttp.put('projects/' + this.incident.slug, {
        status: this.selectedStatus.value
      }).then(function(response) {
        self.incident = response.data
        self.cancelForm();
        self.loadContent()
        self.$toastr('success', self.$t('projects.update.success'))
        self.buttonDisabled = false;
      }, function(error) {
        if (error.status !== 200) {
          self.buttonDisabled = false;
          self.error = error.data.message;
        }
      })
    },
    hideEditUsersModal() {
      this.selectedTask = {}
      this.$refs['re-assign'].hide()
    },
    showEditUsersModal(object) {
      let self = this;
      this.error = '';

      let role_name = [self.permissions.ROLE_CLIENT_ADMIN];

      if (self.incident.type == 'control' || self.incident.type == 'security_measure') {
        role_name.push(self.permissions.ROLE_MEMBER);
        role_name.push(self.permissions.ROLE_VALIDATOR);
      }

      if (this.getClient && this.getClient.parent) {
        role_name.push(self.permissions.ROLE_PARTITION_ADMIN);
      }

      this.selectedAssignUsers = [];
      piincHttp.get('users', { params:
        {
          client: this.getClient.id,
          role_name: role_name,
          per_page: 100
        }
      }).then(function(response) {
        let users = response.data.items
        _.forEach(object.users, function (user) {
          if (_.findIndex(users, function(o) { return o.id == user.id; }) == -1) {
            if (user.id != self.getUser.id) {
              users.push(user)
            }
          }
          if (user.id != self.getUser.id) {
            let matchedUser = _.cloneDeep(user)
            if (user.id == object.owner) {
              matchedUser.owner = true
            }
            self.selectedAssignUsers.push(matchedUser)
          }
        })
        self.allUsers = _.uniq(users)
      }, function() {});

      this.selectedTask = object
      this.$refs['re-assign'].show()
    },
    reassignFormSubmit(evt) {
      this.buttonDisabled = true;
      // Keep the reference in a variable
      // to use it inside function called when promise returned
      let self = this;
      let userIds = [];
      evt.preventDefault();

      if (this.selectedAssignUsers && this.selectedAssignUsers.length) {
        for (var i = 0; i < this.selectedAssignUsers.length; i++) {
          userIds.push(this.selectedAssignUsers[i].id);
        }
      }

      if (userIds && userIds.length) {
        piincHttp.put('tasks/' + this.selectedTask.id, {
          users: userIds
        }).then(function(response) {
          self.hideEditUsersModal();
          self.loadOpenTasks()
          self.$toastr('success', self.$t('tasks.re_assign.success'))
          self.buttonDisabled = false;
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })
      } else {
        self.error = self.$t('tasks.re_assign.required')
        self.buttonDisabled = false;
      }
    },
    showFormFunction() {
      let self = this
      this.selectedStatus = []
      if (this.incident.status) {
        this.selectedStatus = _.find(this.options, function(item) {
          return item.value === self.incident.status;
        });
      }
      this.showStatusForm = true
    },
    openTasksTabClicked() {
      this.loadOpenTasks()
      this.showClosedTasksTab = false
      this.showOpenTasksTab = true
    },
    closedTasksTabClicked() {
      this.loadClosedTasks()
      this.showClosedTasksTab = true;
      this.showOpenTasksTab = false
    },
    loadOpenTasks() {
      let self = this;
      // Call API for open tasks
      piincHttp.get('projects/' + this.incident.slug + '/tasks', { params:
        {
          status: 'Pending',
          per_page: 100,
          client: this.getClient.slug
        }
      }).then(function(response) {
        self.open_tasks = response.data.items;
      }, function() {});
    },
    loadClosedTasks() {
      let self = this;
      // Call API for closed tasks
      piincHttp.get('projects/' + this.incident.slug + '/tasks', { params:
        {
          status: 'Completed',
          per_page: 100,
          client: this.getClient.slug
        }
      }).then(function(response) {
        self.closed_tasks = response.data.items;
      }, function() {});
    },
  },
  created () {
    if (this.getClient && this.getClient.slug) {
      this.loadContent(true);
    }

    this.loadGuides();

  }
}
</script>
